.portal {
  transform: translate(-50%, 60px) !important;
  left: 50% !important;
  overflow: visible !important;

  @apply absolute w-[460px] bg-white p-6 left-0 top-0 rounded-2xl;

  box-shadow: 0 8px 12px -3px rgb(24 39 75 / 6%);
  box-shadow: 0 8px 12px -6px rgb(24 39 75 / 6%);
}

.heading {
  @apply font-bold text-black-2 text-lg mb-4;
}

.subheading {
  @apply font-bold flex items-center justify-center text-black-2 text-lg mb-4;
}

.buttons {
  @apply flex justify-end pt-4 gap-x-2;
}

.error {
  @apply mb-2;
}
