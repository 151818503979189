@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(12);

  .new-item {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex: 1;
    gap: --px-to-rem(12);
  }

  .add-button {
    align-self: flex-start;
    min-width: --px-to-rem(126);
  }
}
