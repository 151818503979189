@import 'libs/northstar/src/styles/pxToRem';

.table {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: --px-to-rem(16);
  border: 1px solid var(--cloud-3);

  > * {
    border-bottom: 1px solid var(--cloud-3);

    &:last-child {
      border-color: transparent;
    }
  }

  .empty-label {
    align-self: center;
    margin-block: --px-to-rem(24);
  }

  .row {
    display: flex;
    align-items: center;
    padding: --px-to-rem(10) --px-to-rem(20);
    gap: --px-to-rem(16);

    transition: all 100ms;

    &:hover:not(:first-child) {
      background-color: var(--cloud-0);

      .action {
        opacity: 1;
      }
    }

    .column {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .action {
      opacity: 0;

      border: none;
      transition: all 100ms;
      margin-left: auto;
      margin-right: --px-to-rem(-10);

      &:focus {
        opacity: 1;
      }
    }

    .hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}
