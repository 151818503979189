@import 'libs/northstar/src/styles/pxToRem';

.portal {
  position: absolute;
  background-color: var(--background-primary);
  height: 100vh;
  right: 0;
  top: 0;
  min-width: 600px;

  .wrapper {
    position: relative;
    padding: --px-to-rem(40);

    .close-icon {
      position: absolute;

      right: 28px;
      top: 28px;

      color: var(--gray-2);
      scale: 1;

      transition: all 0.1s ease-in-out;

      &:hover {
        color: var(--gray-1);
        scale: 1.05;
      }
    }

    .icon {
      margin-bottom: --px-to-rem(12);
    }

    .header {
      margin-bottom: --px-to-rem(24);

      display: flex;
      flex-direction: column;
      gap: --px-to-rem(4);

      .title {
        font-size: --px-to-rem(24);
        font-weight: 600;
      }
    }
  }
}
