@import 'libs/northstar/src/styles/pxToRem';

.primaryButton {
    width: --px-to-rem(80);
  }

.container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: --px-to-rem(10);
}

.heading {
    margin-bottom: --px-to-rem(8);
}

.input {
    width: --px-to-rem(200);
}

.dropdown {
    width: --px-to-rem(200);
}

.banner {
    margin-top: --px-to-rem(8);
}