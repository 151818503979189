@import 'libs/northstar/src/styles/pxToRem';

.container {
  padding: --px-to-rem(14) --px-to-rem(16);

  border-radius: --px-to-rem(8);
  border: 1px solid var(--cloud-3);
  background: var(--background-primary);

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: background-color 0.2s ease;

  .icon {
    rotate: 0;

    color: var(--gray-2);

    transition: all 0.2s ease;

    &--open {
      rotate: 180deg;
      color: var(--gray-1);
    }
  }

  &--disabled {
    border: 1px solid var(--cloud-3);
    opacity: 0.5;
    background: var(--cloud-1);
    color: var(--gray-2);
    cursor: not-allowed;
  }

  &:not(.container--disabled):hover  {
    background-color: var(--cloud-0);

    .icon {
      color: var(--gray-1);
    }
  }
}
