.portal {
  @apply absolute w-2/3 bg-white h-screen right-0 top-0 p-6 pt-[60px];
}

.header__outer {
  @apply border rounded-2xl my-2 px-4 py-4;
}

.header__container {
  @apply flex gap-3 items-center content-center;
}

.header__toggle {
  @apply flex-none m-0;
}

.heading {
  @apply ml-4;
}

.heading--disabled {
  @apply text-gray-2;
}

.confirmation-toast {
  width: 83.3333% !important;
  max-width: max-content !important;
}

.copy.copy {
  @apply text-gray-1;
}

.copy__url.copy__url {
  @apply font-bold text-xs text-primary-2 underline;
}

.banner {
  @apply mb-4;
}
