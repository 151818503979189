.container {
  @apply flex w-full;
}

.options__container {
  @apply pr-6;

  max-width: max-content;
}

.views__container {
  @apply mt-[28px]  flex  justify-center w-full h-full;

  width: 67.5%;
}
