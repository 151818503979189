.container {
  @apply mb-6;
}

.text {
  @apply text-gray-1 text-sm my-0 text-left leading-4 mb-4;
}

.heading {
  @apply text-gray-2 text-sm text-black-2 mb-1;
}

.strong {
  @apply text-black;
}

.input-wrapper {
  @apply flex gap-2 max-h-[42px];
}
