.empty-heading {
  @apply max-w-[226px] h-[1.375rem] bg-[#F6F7F980]/50 rounded-md mb-1;
}

.empty-paragraph {
  @apply w-full h-[0.875rem] bg-[#F6F7F980]/50 rounded-md mb-1;
}

.empty-paragraph:last-child {
  @apply mb-0;
}
