.paper {
  @apply py-3;
}

.wrapper {
  @apply flex justify-between items-start;
}

.panel {
  @apply flex flex-col;
}

.panel--left {
  @apply items-start;
}

.mfa {
  @apply py-4 pl-8;
}

.toggle {
  @apply flex items-center mt-2;
}

.toggleLabel {
  @apply pl-4 gap-0;
}

.title {
  @apply capitalize py-2 font-bold;
}

.subtitle {
  @apply capitalize font-bold;
}

.inline {
  @apply flex items-end justify-center gap-2;
}

.inline--margin {
  @apply mb-2;
}

.description {
  @apply text-gray-1;
}

.divider {
  @apply border-b border-b-cloud-2;
}
