@import 'libs/northstar/src/styles/pxToRem';

.card {
  display: flex;

  .section {
    overflow: hidden;
    border: 1px solid;
    box-sizing: content-box;
    border-radius: --px-to-rem(12);
    border-color: var(--cloud-0);

    display: flex;
    flex-direction: column;
    flex: 1;

    transition: all 0.3s ease-in-out;

    &[data-state='open']:not(&[data-allowexpand='false']) {
      border-color: var(--cloud-3);
    }

    &[data-allowexpand='false']:hover {
      border-color: var(--cloud-3);
    }

    .header {
      display: flex;
      align-items: center;
      gap: --px-to-rem(16);
      padding-left: --px-to-rem(16);
      background-color: var(--cloud-0);

      flex: 1;
      height: 100%;

      transition: background-color 0.3s ease-in-out;

      .trigger {
        width: 100%;
        display: flex;
        align-items: center;
        gap: --px-to-rem(12);

        flex: 1;
        height: 100%;

        padding-block: --px-to-rem(18);
        padding-right: --px-to-rem(16);

        &--no-pointer {
          cursor: default;
        }

        &--with-description {
          padding-block: --px-to-rem(13);
        }

        .label {
          display: flex;
          gap: --px-to-rem(8);
          align-items: center;

          margin-right: auto;

          .text {
            display: flex;
            flex-direction: column;

            .titleWithTags {
              display: flex;
              gap: --px-to-rem(8);
              align-items: center;

              .tags {
                display: flex;
                gap: --px-to-rem(2);
                align-items: center;
              }
            }
          }
        }

        .notification {
          display: block;
          border-radius: 9999px; // circle
          width: --px-to-rem(8);
          height: --px-to-rem(8);
          min-width: --px-to-rem(8);
          min-height: --px-to-rem(8);
          background-color: var(--orange-1);
        }

        .dropdownIcon {
          transition: transform 0.3s ease-in-out;

          &--clickable {
            transform: rotate(-90deg);
          }
        }

        &[data-state='closed'] .dropdownIcon:not(.dropdownIcon--clickable) {
          transform: rotate(0deg);
        }

        &[data-state='open'] .dropdownIcon:not(.dropdownIcon--clickable) {
          transform: rotate(-180deg);
        }
      }
    }

    .content {
      overflow: hidden;
      outline: 1px solid var(--cloud-3); //Outline instead of border to avoid layout shift

      &[data-state='open'] {
        animation: slideDown 0.3s ease-in-out;
      }

      &[data-state='closed'] {
        outline: 0;
        animation: slideUp 0.3s ease-in-out forwards;
      }

      @keyframes slideDown {
        from {
          height: 0;
        }
        to {
          height: var(--radix-accordion-content-height);
        }
      }

      @keyframes slideUp {
        from {
          height: var(--radix-accordion-content-height);
        }
        to {
          height: 0;
        }
      }
    }
  }
}
