.container {
  @apply grid grid-cols-12 gap-2;
}

.dropdown {
  @apply col-span-2;
}

.dropdown > button {
  @apply py-[13px];
}

.dropdown svg {
  @apply w-[10px];
}

.dropdownContent {
  @apply bg-white border rounded-lg py-2 !absolute top-0 z-30;

  box-shadow: 0 8px 12px -6px rgb(24 39 7 / 6%),
    0 8px 12px -3px rgb(24 39 7 / 6%);
}

.dropdownItem {
  @apply items-center px-3 py-[11px] hover:bg-cloud-1 focus:bg-cloud-1 font-medium text-sm w-[112px];
}

.trigger {
  @apply text-black text-sm;
}

.inputShort {
  @apply col-span-2;
}

.input {
  @apply col-span-6;
}
