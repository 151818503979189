@import 'libs/northstar/src/styles/pxToRem';

button.button {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 1px 2px 0px rgba(24, 39, 75, 0.04);

  &--primary {
    background-color: var(--primary-1);
    color: var(--white);
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: var(--primary-dark);
    }

    &:active {
      background-color: var(--primary-2);
    }

    &:disabled {
      background-color: var(--cloud-2);
    }
  }

  &--secondary {
    border: --px-to-rem(1) solid var(--cloud-3);
    background: var(--white);
    color: var(--Black-2);
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: var(--cloud-0);
    }

    &:active {
      background-color: var(--white);
    }

    &:disabled {
      background-color: var(--white);
    }

    &.button--large {
      padding: --px-to-rem(13) --px-to-rem(19);
    }

    &.button--medium {
      padding: --px-to-rem(9) --px-to-rem(14);
    }

    &.button--small {
      padding: --px-to-rem(5) --px-to-rem(9);
    }
  }

  &--large {
    padding: --px-to-rem(14) --px-to-rem(20);
    border-radius: --px-to-rem(8);
    gap: --px-to-rem(8);

    &--icon {
      padding: --px-to-rem(14) --px-to-rem(20) --px-to-rem(14) --px-to-rem(16);
    }
  }

  &--medium {
    padding: --px-to-rem(10) --px-to-rem(15);
    border-radius: --px-to-rem(8);
    gap: --px-to-rem(8);

    &--icon {
      padding: --px-to-rem(14) --px-to-rem(20) --px-to-rem(14) --px-to-rem(16);
    }
  }

  &--small {
    padding: --px-to-rem(6) --px-to-rem(10);
    border-radius: --px-to-rem(6);
    gap: --px-to-rem(6);

    &--icon {
      padding: --px-to-rem(8);
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--gray-2);
  }
}
