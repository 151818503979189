@import 'libs/northstar/src/styles/pxToRem';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: --px-to-rem(14) --px-to-rem(16);

  border: 1px solid var(--cloud-3);

  overflow: hidden;

  background-color: var(--background-primary);
  border-radius: --px-to-rem(8);
  transition: background-color 0.2s ease;

  .item {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
    gap: --px-to-rem(8);

    .text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .trigger {
    margin-left: auto;
    color: var(--gray-2);

    transition: color 0.2s ease;
  }

  &:not(.container--disabled):hover  {
    background-color: var(--cloud-0);

    .trigger {
      color: var(--gray-1);
    }
  }

  &--disabled {
    cursor: not-allowed;
    background-color: var(--cloud-3);
    color: var(--gray-1);
  }
}
