.wrapper {
  @apply flex justify-between w-full items-center text-black-2;
}

.wrapper svg {
  @apply w-[1.125rem] h-[1.125rem];
}

.wrapper svg path {
  @apply fill-gray-2;
}
