@import 'libs/northstar/src/styles/pxToRem.scss';

.wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  background-color: var(--background-primary);
  border-bottom: 1px solid var(--cloud-3);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  .topBar {
    display: flex;
    align-items: center;

    width: 100%;
    max-width: var(--content-max-width);
    padding-left: var(--page-inline-padding-left);
    padding-right: var(--page-inline-padding-right);

    background-color: var(--background-primary);

    .dropdown {
      padding: --px-to-rem(8) --px-to-rem(12);

      display: flex;
      height: 100%;

      border-right: 1px solid var(--cloud-3);
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }

    .help {
      margin-left: auto;

      display: flex;
      align-items: center;
      gap: --px-to-rem(12);

      padding: --px-to-rem(8);

      .button {
        display: flex;
        align-items: center;

        padding: --px-to-rem(8) --px-to-rem(12) --px-to-rem(8) --px-to-rem(8);
        gap: --px-to-rem(8);

        color: var(--black-2);

        transition: color 0.2s ease;

        .icon {
          color: var(--gray-2);

          transition: color 0.2s ease;
        }

        &:hover {
          color: var(--black-1);

          .icon {
            color: var(--gray-1);
          }
        }
      }
    }
  }
}
