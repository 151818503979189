.text {
  @apply text-gray-1 text-sm my-0 text-left leading-4 mb-4;
}

.text a {
  @apply font-bold text-primary-1;
}

.heading {
  @apply text-gray-2 text-sm text-black-2 mb-1;
}

.button {
  @apply max-w-[126px] mt-4;
}

.container {
  @apply flex flex-col pr-6;
}
