@import 'libs/northstar/src/styles/pxToRem';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: auto;

  .wrapper {
    margin-top: 57px;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    flex: 1;

    .save-banner {
      margin-top: 0;

      transition: margin-top 0.2s ease-in-out;

      &:has(> :nth-child(1)) {
        margin-top: 52px;
      }
    }

    .content {
      max-width: var(--content-max-width);
      width: 100%;

      display: flex;
      justify-content: center;

      gap: --px-to-rem(28);

      flex: 1;

      .sidebar {
        padding: 26px 0 26px 66px;
        box-sizing: content-box;

        min-width: 200px;
        max-width: 200px;
        width: 100%;
      }

      .outlet {
        padding: 26px 62.5px 26px 0;

        max-width: 100%;
        position: relative;
        z-index: 0;
        display: flex;
        flex: 1;

        overflow-x: hidden;
      }

      &--without-padding {
        padding: 0;
      }
    }
  }
}
