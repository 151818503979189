@import 'libs/northstar/src/styles/pxToRem.scss';

.text {
  text-align: left;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.code-0 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: --px-to-rem(12);
  letter-spacing: -0.025em;
  line-height: --px-to-rem(18);
}

.code-1 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: --px-to-rem(12);
  letter-spacing: --px-to-rem(-0.24);
  line-height: --px-to-rem(16);
}

.code-2 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: --px-to-rem(14);
  letter-spacing: --px-to-rem(-0.28);
  line-height: --px-to-rem(16);
}

.anchor {
  letter-spacing: 0.025em;
  font-weight: 700;
}

.paragraph-0 {
  font-size: --px-to-rem(10);
  line-height: --px-to-rem(12);
}

.paragraph-1 {
  font-size: --px-to-rem(12);
  line-height: --px-to-rem(18);
}

.paragraph-2 {
  font-size: --px-to-rem(14);
  line-height: --px-to-rem(20);
}

.paragraph-3 {
  font-size: --px-to-rem(16);
  line-height: --px-to-rem(24);
}

.title {
  font-size: --px-to-rem(24);
  line-height: --px-to-rem(32);
}

.subtitle {
  font-size: --px-to-rem(18);
  line-height: --px-to-rem(24);
}

.numbers {
  font-size: --px-to-rem(28);
  line-height: --px-to-rem(36);
  font-weight: 700;
}

.button-medium {
  font-size: --px-to-rem(14);
  line-height: --px-to-rem(20);
  font-weight: 700;
}

.button-small {
  font-size: --px-to-rem(12);
  line-height: --px-to-rem(16);
  font-weight: 700;
}

.link {
  font-size: --px-to-rem(14);
  font-weight: 700;
  line-height: --px-to-rem(16);
  text-decoration-line: underline;
}

.black-2 {
  color: var(--black-2);
}

.gray-1 {
  color: var(--gray-1);
}

.gray-2 {
  color: var(--gray-2);
}

.primary-1 {
  color: var(--primary-1);
}

.inherit {
  color: inherit;
}
