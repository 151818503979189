@import './styles/variables';
@import './styles/fonts.css';
@import './styles/preflight.css';
@import './styles/pxToRem';

#root {
  white-space: pre-line;
  display: flex;
  height: 100vh;
}

body {
  background-color: var(--background-primary);
  font-family: 'DM Sans', sans-serif;
}
