.input {
  @apply mb-6 flex-none max-w-[357px] z-0;
}

.heading {
  @apply mb-2;
}

.description {
  @apply text-gray-2 mb-3;
}

.dropdown {
  @apply pb-2 max-w-[357px];
}

.dropdown svg {
  @apply w-[10px];
}

.dropdownContent {
  @apply bg-white border rounded-lg py-2 absolute z-10;

  box-shadow: 0 8px 12px -6px rgb(24 39 7 / 6%),
    0 8px 12px -3px rgb(24 39 7 / 6%);
}

.dropdownItem {
  @apply items-center px-3 py-[14px] hover:bg-cloud-1 focus:bg-cloud-1 font-medium text-sm min-w-[357px];
}

.app {
  @apply flex items-center gap-2;
}

.app svg {
  @apply w-4 h-4;
}
